<template>
  <div>
    <b-row>
      <b-col lg="6" md="6" sm="6" class="heading-title">
        <div class="d-flex justify-content-between">
					<div>
						<h4>Parent Venue View</h4>
					</div>
					<div>
						<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
          <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
          <b-breadcrumb-item to="/venues">Parent Venue</b-breadcrumb-item>
          <b-breadcrumb-item active>View</b-breadcrumb-item>
        </b-breadcrumb>
						</div>
						</div>
       
      </b-col>
      <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
    </b-row>
    <br />

    <b-card class="mb-4 w-100">
      <div class="text-left mb-4">
        <b-button to="/venues" variant="primary" class="mt-2"> Back </b-button>
      </div>
      <b-row>
        <!-- part 1(1) -->
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="lname2">Company Name:</label>
            </b-col>
            <b-col sm="8">
              <span>
                {{ form.c_name }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="nname2">Venue Name:</label>
            </b-col>
            <b-col sm="8">
              <span>
                {{ form.v_name }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="lname2">City:</label>
            </b-col>
            <b-col sm="8">
              <span>
                {{ form.city }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="uname2">State:</label>
            </b-col>
            <b-col sm="8">
              <span>
                {{ form.state }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <!--  -->
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="nname2">Country:</label>
            </b-col>
            <b-col sm="8">
              <span>
                {{ form.country }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="uname2"> Pincode:</label>
            </b-col>
            <b-col sm="8">
              <span>
                {{ form.pincode }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="uname2">Address:</label>
            </b-col>
            <b-col sm="8">
              <span>
                {{ form.address }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="lname2">Show on map:</label>
            </b-col>
            <b-col sm="8">
              <span v-if="form.show_map == 0"> No </span>
              <span v-if="form.show_map == 1"> Yes </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" v-if="form.facebook_url !== null">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="uname2">Facebook Url:</label>
            </b-col>
            <b-col sm="8">
              <b-link :href="form.facebook_url" target="_blank">{{
                form.facebook_url
              }}</b-link>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="6" v-if="form.instagram_url !== null">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="uname2">Instagram Url:</label>
            </b-col>
            <b-col sm="8">
              <b-link :href="form.instagram_url" target="_blank">{{
                form.instagram_url
              }}</b-link>
            </b-col>
          </b-row>
        </b-col>
        <!-- part 1(2) -->

        <b-col cols="6" v-if="form.youtube_url !== null">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="uname2">Youtube Url:</label>
            </b-col>
            <b-col sm="8">
              <b-link :href="form.youtube_url" target="_blank">{{
                form.youtube_url
              }}</b-link>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" v-if="form.twitter_url !== null">
          <b-row class="py-2 custon_border align-items-center">
            <b-col sm="4" class="text-left">
              <label class="fw-medium mb-0" for="uname2">Twitter Url:</label>
            </b-col>
            <b-col sm="8">
              <b-link :href="form.twitter_url" target="_blank">{{
                form.twitter_url
              }}</b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "parent_venue_view",

  data: () => ({
    title: "parent_venue_view",
    form: {
      c_name: "",
      v_name: "",
      address: "",
      state: "",
      city: "",
      country: "",
      show_map: 0,
      pincode: "",
      facebook_url: "",
      instagram_url: "",
      youtube_url: "",
      twitter_url: "",
    },
  }),
  components: {},

  methods: {
    getData() {
      this.venue_id = this.$route.params.id;

      axios
        .post("/api/edit_parent_venue", {
          venue_id: this.venue_id,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.venue_id = resp.data.parent_venue.parent_venue_id;
              this.form.v_name = resp.data.parent_venue.venue_name;
              this.form.c_name = resp.data.parent_venue.venue_company_name;
              this.form.address = resp.data.parent_venue.venue_address;
              this.form.country = resp.data.parent_venue.venue_country;
              this.form.state = resp.data.parent_venue.venue_state;
              this.form.city = resp.data.parent_venue.venue_city;
              this.form.pincode = resp.data.parent_venue.venue_pincode;
              this.form.show_map = resp.data.parent_venue.show_map;
              (this.form.facebook_url = resp.data.parent_venue.facebook_url),
                (this.form.instagram_url =
                  resp.data.parent_venue.instagram_url),
                (this.form.youtube_url = resp.data.parent_venue.youtube_url),
                (this.form.twitter_url = resp.data.parent_venue.twitter_url);
            } else {
              this.$router.push("/venues");
            }
          }
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
.form-group {
  margin: 0;
}
</style>